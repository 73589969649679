<template>
  <div>
    <Header />
    <transition appear name="route" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<style lang="scss">
// Roboto font
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital,wght@0,300;0,400;0,700;1,300&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
span,
b,
i,
u,
a,
ul,
li,
label,
button,
p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
// Animation effect
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.3s ease;
}
.route-enter-to,
.route-leave {
  opacity: 1;
  transform: translateY(0);
}
// Modal effect
.fade {
  transition: opacity 0.3s linear !important;
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faBox } from "@fortawesome/free-solid-svg-icons";
library.add(faTasks);
library.add(faSearch);
library.add(faShoppingCart);
library.add(faCartPlus);
library.add(faPlusSquare);
library.add(faBackward);
library.add(faForward);
library.add(faThumbsUp);
library.add(faBox);
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
  },
};
</script>
