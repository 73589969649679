<template>
  <div>
    <b-modal size="lg" id="foodOptionsModal" :no-close-on-backdrop="true">
      <!-- Close (x) btn -->
      <template #modal-header-close>
        <div @click="reset()">x</div>
      </template>
      <!-- Food, quantity -->
      <template #modal-title>
        <ul class="list-unstyled">
          <li><b>Artikel</b></li>
          <li class="food-name">
            {{ clicked_food_name }}
            <input
              v-model="quantity"
              disabled
              class="width-small text-center ml-1"
              type="number"
            />
          </li>
          <li>
            <b-button @click="quantity++" variant="warning mr-1">+</b-button>
            <b-button
              :disabled="quantity == 1"
              @click="quantity--"
              variant="warning"
              >-</b-button
            >
          </li>
        </ul>
      </template>

      <!-- Choose Ingredients -->
      <template>
        <ul class="list-inline ingredients">
          <li
            v-for="ingredient in filteredIngredients"
            :key="ingredient.index"
            class="list-inline-item ingredient-button"
          >
            <label class="mr-3"
              >{{ ingredient.name }} (+{{ ingredient.preis }} CHF)</label
            >
            <input
              type="checkbox"
              v-model="selectedIngredients"
              :value="ingredient"
              variant="warning"
            />
          </li>
        </ul>
      </template>

      <!-- Message -->
      <template>
        <div>
          <div class="form-group">
            <label for="">Sonstiges</label>
            <textarea
              v-model="message"
              placeholder="z. B. ohne Zwiebeln"
              class="form-control"
              rows="3"
            ></textarea>
          </div>
        </div>
      </template>

      <!-- Add to Cart btn -->
      <template #modal-footer>
        <b-button class="add-to-cart" variant="warning" @click="addToCart()">
          <ul class="list-inline">
            <li class="list-inline-item">
              <font-awesome-icon icon="cart-plus" size="lg" />
            </li>
            <li class="list-inline-item">{{ totalPrice }} CHF</li>
          </ul>
        </b-button>
      </template>
    </b-modal>
    <!-- Added to cart Msg -->
    <b-alert variant="success fixed-bottom" :show="addedToCartMsg">
      <transition appear name="route" mode="out-in">
        <b-container>
          <b-row>
            <b-col md="3" class="mb-2">
              <font-awesome-icon icon="cart-plus" size="lg" class="mr-3" />
              <b class="mr-2">{{ cartItems.length }}</b>
              <b>Artikel</b> hinzugefügt
            </b-col>
            <b-col offset-md="6" md="2">
              <router-link to="/cart"
                ><b-button variant="success mr-3"
                  >zum Warenkorb</b-button
                ></router-link
              >
            </b-col>
            <b-col md="1">
              <div @click="addedToCartMsg = false" class="close mt-1">
                <a href="#">x</a>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </transition>
    </b-alert>
  </div>
</template>

<style lang="scss" scoped>
.food-name {
  font-size: 16px;
}
.btn-small {
  margin-bottom: 5px;
  margin-right: 5px;
}
.width-small {
  width: 15%;
  font-size: 13px;
}
.active {
  background: #7b621a !important;
  color: white !important;
}
.add-to-cart {
  li {
    font-size: 12px;
    padding-top: 15px;
  }
}

.ingredients {
  li {
    margin-bottom: 5px;
  }
  label {
    font-size: 12px;
  }
  .ingredient-button {
    position: relative;
    background-color: #ffc107;
    padding: 2px 15px 2px 15px;
    border-radius: 40px;
    input[type="checkbox"] {
      position: absolute;
      top: 8px;
      right: 12px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapState([
      "api_url",
      "clicked_food_name",
      "cartItems",
      "pizza_size_price",
      "pizza_size_text",
    ]),
    filteredFoods() {
      return this.foods.filter((item) => {
        return this.clicked_food_name === item.name;
      });
    },
    food_category_name() {
      let food_category_name = "";
      this.filteredFoods.forEach(function (food) {
        food_category_name = food.category.data.attributes.name;
      });
      return food_category_name;
    },
    filteredIngredients() {
      return this.ingredients.filter((x) => {
        if (x.categories && x.categories.data && x.categories.data.length > 0) {
          return x.categories.data.some(
            (category) => category.attributes.name === this.food_category_name
          );
        }
        return false;
      });
    },
    ingredientFee() {
      let sum = 0;
      this.selectedIngredients.forEach(function (ingredient) {
        sum += ingredient.preis;
      });
      return sum;
    },
    totalPrice() {
      let sum, quantity, ingredientFee, pizza_size_price;
      sum = 0;
      quantity = this.quantity;
      pizza_size_price = this.pizza_size_price;
      ingredientFee = this.ingredientFee;
      this.filteredFoods.forEach(function (food) {
        sum = quantity * (food.preis + ingredientFee + pizza_size_price);
      });
      return sum;
    },
    envImg() {
      let img = "";
      let isLocalEnv = window.location.href.includes("localhost");
      if (isLocalEnv) {
        img =
          this.filteredFoods[0].bilder.data.attributes.formats.thumbnail.url;
      } else {
        img = this.filteredFoods[0].bilder.data.attributes.url;
      }
      return img;
    },
  },
  data() {
    return {
      foods: [],
      ingredients: [],
      quantity: 1,
      message: "",
      addedToCartMsg: false,
      selectedIngredients: [],
    };
  },
  mounted() {
    this.getFoods();
    this.getIngredients();
  },
  methods: {
    getFoods() {
      axios.get(this.api_url + "/api/foods?populate=*").then((response) => {
        this.foods = response.data.data.map((item) => item.attributes);
      });
    },

    getIngredients() {
      axios
        .get(this.api_url + "/api/ingredients?populate=*")
        .then((response) => {
          this.ingredients = response.data.data.map((item) => item.attributes);
        });
    },

    addToCart() {
      this.$store.commit("SET_CART_ITEMS", {
        food: this.clicked_food_name,
        category: this.filteredFoods[0].category.data.attributes.name,
        quantity: this.quantity,
        ingredients: this.selectedIngredients,
        pizza_size: this.pizza_size_text,
        message: this.message,
        price: this.totalPrice,
        img: this.envImg,
      });
      this.reset();
      this.$bvModal.hide("foodOptionsModal");
      this.addedToCartMsg = true;
    },
    reset() {
      this.quantity = 1;
      this.selectedIngredients = [];
      this.message = "";
      this.$store.commit("SET_PIZZA_SIZE_PRICE", 0);
      this.$store.commit("SET_PIZZA_SIZE_TEXT", "");
    },
  },
};
</script>
