<template>
  <div>
    <b-modal id="orderSuccessMsg" hide-footer>
      <template #modal-title> Bestellung erfolgreich</template>
      <div class="d-block text-center">
        <font-awesome-icon icon="box" class="mb-1 ml-2" size="lg" />
        <h5>Die Bestellung ist erfolgreich</h5>
        <h5>Vielen dank!</h5>
      </div>
      <b-button class="mt-3" block @click="hideAndReload()"
        >Schliessen</b-button
      >
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "VFoodOrderSuccess",
  methods: {
    hideAndReload() {
      this.$bvModal.hide("orderSuccessMsg");
      window.location.reload();
    },
  },
};
</script>

<style></style>
