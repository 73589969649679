<template>
  <div>
    <b-container fluid>
      <b-row class="header">
        <b-container>
          <b-row>
            <b-col md="2">
              <div class="logo" @click="resetCategory()">
                <img
                  v-if="node_env == 'production'"
                  class="w-100 logo"
                  :src="info.logo.data.attributes.url"
                />
                <img
                  v-else
                  class="w-100 logo"
                  :src="api_url + info.logo.data.attributes.url"
                />
              </div>
            </b-col>

            <b-col class="mt-4" md="4">
              <h4 class="title">
                <b>{{ info.title }}</b>
              </h4>
              <vue-simple-markdown
                class="title"
                :source="info.paragraph"
              ></vue-simple-markdown>
            </b-col>
            <b-col offset-md="4" md="2">
              <router-link to="/cart">
                <b-badge class="float-right" variant="light"
                  >{{ cartItemsCount }}<span class="sr-only"></span
                ></b-badge>

                <font-awesome-icon
                  icon="shopping-cart"
                  size="lg"
                  class="mr-2 mt-2 float-right cart-icon" /></router-link
            ></b-col>
          </b-row>
        </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.header {
  background: rgb(238, 225, 38);
  padding: 15px 0px 15px 0px;
  color: white;
}
.cart-icon {
  color: #525149;
}
.logo {
  cursor: pointer;
}
.title {
  color: #333;
  font-size: 15px;
}
</style>
<script>
import axios from "axios";
export default {
  mounted() {
    this.getInfo();
  },
  data() {
    return {
      info: [],
    };
  },
  computed: {
    cartItemsCount() {
      return this.$store.state.cartItems.length;
    },
    api_url() {
      return this.$store.state.api_url;
    },
    node_env() {
      return this.$store.state.node_env;
    },
  },
  methods: {
    getInfo() {
      axios.get(this.api_url + "/api/info?populate=*").then((response) => {
        this.info = response.data.data.attributes;
      });
    },
    resetCategory() {
      this.$store.commit("RESET_CATEGORY");
      this.$router.push("/");
    },
  },
};
</script>
