import Vue from "vue";
import VueRouter from "vue-router";
import Foods from "../views/Foods.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faSearch);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Foods",
        component: Foods,
    },
    {
        path: "/cart",
        name: "Cart",
        component: () =>
            import ("../views/Cart.vue"),
    },
    {
        path: "/personal-infos",
        name: "PersonalInfos",
        component: () =>
            import ("../views/PersonalInfos.vue"),
    },
    {
        path: "/qibla",
        name: "Qibla",
        component: () =>
            import ("../views/Qibla.vue"),
    },
];

const router = new VueRouter({
    routes,
});

export default router;