<template>
  <div>
    <FoodMenu />
    <b-container v-if="status.open">
      <!-- if Searched by Category -->
      <b-row v-if="this.selected_category">
        <b-col
          md="3"
          cols="6"
          v-for="food in filteredFoodsByCategory"
          :key="food.id"
        >
          <transition appear name="route" mode="out-in">
            <div>
              <img
                v-if="node_env == 'production'"
                class="w-100"
                :src="food.bilder.data.attributes.url"
              />
              <img
                v-else
                class="w-100"
                :src="api_url + food.bilder.data.attributes.url"
              />
              <i>{{ food.beschreibung }}</i> <br />
              <b-button
                v-if="food.category.data.attributes.name !== 'Pizza'"
                @click="openFoodOptions(food.name, 0, '')"
                variant="warning mb-3 w-100"
              >
                <font-awesome-icon icon="plus-square" size="lg" class="mr-2" />
                {{ food.name }}</b-button
              >
              <div v-if="food.category.data.attributes.name == 'Pizza'">
                {{ food.name }}
                <b-button
                  @click="openFoodOptions(food.name, -2, '24cm')"
                  variant="warning mb-3 w-100"
                >
                  <font-awesome-icon
                    icon="plus-square"
                    size="lg"
                    class="mr-2"
                  />
                  klein <br />
                  (24cm)</b-button
                >
                <b-button
                  @click="openFoodOptions(food.name, 0, '30cm')"
                  variant="warning mb-3 w-100"
                >
                  <font-awesome-icon
                    icon="plus-square"
                    size="lg"
                    class="mr-2"
                  />
                  normal <br />
                  (30cm)</b-button
                >

                <b-button
                  @click="openFoodOptions(food.name, 12, '40cm')"
                  variant="warning mb-3 w-100"
                >
                  <font-awesome-icon
                    icon="plus-square"
                    size="lg"
                    class="mr-2"
                  />
                  gross <br />
                  (40cm)</b-button
                >
              </div>
            </div>
          </transition>
        </b-col>
      </b-row>

      <!-- if Searched by Food Name -->
      <transition appear name="route" mode="out-in">
        <b-row v-if="this.searched_food">
          <b-col
            md="3"
            cols="6"
            v-for="food in filteredFoodsByName"
            :key="food.id"
          >
            <transition appear name="route" mode="out-in">
              <div>
                <img
                  v-if="node_env == 'production'"
                  class="w-100"
                  :src="food.bilder.data.attributes.url"
                />
                <img
                  v-else
                  class="w-100"
                  :src="api_url + food.bilder.data.attributes.url"
                />
                <i>{{ food.beschreibung }}</i> <br />
                <b-button
                  v-if="food.category.data.attributes.name !== 'Pizza'"
                  @click="openFoodOptions(food.name, 0, '')"
                  variant="warning mb-3 w-100"
                >
                  <font-awesome-icon
                    icon="plus-square"
                    size="lg"
                    class="mr-2"
                  />
                  {{ food.name }}</b-button
                >
                <div v-if="food.category.data.attributes.name == 'Pizza'">
                  {{ food.name }}
                  <b-button
                    @click="openFoodOptions(food.name, -2, '24cm')"
                    variant="warning mb-3 w-100"
                  >
                    <font-awesome-icon
                      icon="plus-square"
                      size="lg"
                      class="mr-2"
                    />
                    klein <br />
                    (24cm)</b-button
                  >
                  <b-button
                    @click="openFoodOptions(food.name, 0, '30cm')"
                    variant="warning mb-3 w-100"
                  >
                    <font-awesome-icon
                      icon="plus-square"
                      size="lg"
                      class="mr-2"
                    />
                    normal <br />
                    (30cm)</b-button
                  >

                  <b-button
                    @click="openFoodOptions(food.name, 12, '40cm')"
                    variant="warning mb-3 w-100"
                  >
                    <font-awesome-icon
                      icon="plus-square"
                      size="lg"
                      class="mr-2"
                    />
                    gross <br />
                    (40cm)</b-button
                  >
                </div>
              </div>
            </transition>
          </b-col>
        </b-row>
      </transition>

      <!-- if Searched by none Food Name -->
      <transition appear name="route" mode="out-in">
        <b-row
          v-if="
            (!this.searched_food && !this.selected_category) ||
            this.selected_category == 'Alles'
          "
        >
          <b-col md="3" cols="6" v-for="food in sortedFoods" :key="food.id">
            <transition appear name="route" mode="out-in">
              <div>
                <img
                  v-if="node_env == 'production'"
                  class="w-100"
                  :src="food.bilder.data.attributes.url"
                />
                <img
                  v-else
                  class="w-100"
                  :src="api_url + food.bilder.data.attributes.url"
                />
                <i>{{ food.beschreibung }}</i> <br />
                <b-button
                  v-if="food.category.data.attributes.name !== 'Pizza'"
                  @click="openFoodOptions(food.name, 0, '')"
                  variant="warning mb-3 w-100"
                >
                  <font-awesome-icon
                    icon="plus-square"
                    size="lg"
                    class="mr-2"
                  />
                  {{ food.name }}</b-button
                >
                <div v-if="food.category.data.attributes.name == 'Pizza'">
                  {{ food.name }}
                  <b-button
                    @click="openFoodOptions(food.name, -2, '24cm')"
                    variant="warning mb-3 w-100"
                  >
                    <font-awesome-icon
                      icon="plus-square"
                      size="lg"
                      class="mr-2"
                    />
                    klein <br />
                    (24cm)</b-button
                  >
                  <b-button
                    @click="openFoodOptions(food.name, 0, '30cm')"
                    variant="warning mb-3 w-100"
                  >
                    <font-awesome-icon
                      icon="plus-square"
                      size="lg"
                      class="mr-2"
                    />
                    normal <br />
                    (30cm)</b-button
                  >

                  <b-button
                    @click="openFoodOptions(food.name, 12, '40cm')"
                    variant="warning mb-3 w-100"
                  >
                    <font-awesome-icon
                      icon="plus-square"
                      size="lg"
                      class="mr-2"
                    />
                    gross <br />
                    (40cm)</b-button
                  >
                </div>
              </div>
            </transition>
          </b-col>
        </b-row>
      </transition>

      <!-- Food Options modal -->
      <FoodOptions />
      <!-- Successful Order message -->
      <VFoodOrderSuccess />
    </b-container>
    <b-container v-else>
      <b-row>
        <b-col md="12">
          <div class="text-center">
            <p>{{ status.text }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
button {
  font-size: 12px;
}
</style>

<script>
import { mapState } from "vuex";
import axios from "axios";
import FoodMenu from "@/components/FoodMenu.vue";
import FoodOptions from "@/components/FoodOptions.vue";
import VFoodOrderSuccess from "@/components/VFoodOrderSuccess.vue";
export default {
  name: "Foods",
  components: {
    FoodMenu,
    FoodOptions,
    VFoodOrderSuccess,
  },
  data() {
    return {
      foods: [],
      status: null,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.show_order_successful_msg) {
        this.$bvModal.show("orderSuccessMsg");
      }
    }, 500);
    this.getFoods();
    this.getStatus();
  },
  computed: {
    ...mapState([
      "api_url",
      "selected_category",
      "searched_food",
      "show_order_successful_msg",
      "node_env",
    ]),
    filteredFoodsByName() {
      return this.foods.filter((x) =>
        x.name.toLowerCase().includes(this.searched_food)
      );
    },
    filteredFoodsByCategory() {
      return this.foods.filter(
        (x) => x.category.data.attributes.name == this.selected_category
      );
    },
    sortedFoods() {
      const sortedFoods = [...this.foods];
      return sortedFoods.sort((a, b) => {
        return a.category.data.attributes.name.localeCompare(
          b.category.data.attributes.name
        );
      });
    },
  },
  methods: {
    getFoods() {
      axios.get(this.api_url + "/api/foods?populate=*").then((response) => {
        this.foods = response.data.data.map((item) => item.attributes);
      });
    },
    async getStatus() {
      try {
        const response = await axios.get(this.api_url + "/api/status");
        this.status = response.data.data.attributes;
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    },
    openFoodOptions(food_name, pizza_size_price, pizza_size_text) {
      this.$store.commit("SET_CLICKED_FOOD_NAME", food_name);
      this.$store.commit("SET_PIZZA_SIZE_PRICE", pizza_size_price);
      this.$store.commit("SET_PIZZA_SIZE_TEXT", pizza_size_text);
      this.$bvModal.show("foodOptionsModal");
    },
  },
};
</script>
