import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        api_url: process.env.VUE_APP_URL, //from .env file
        selected_category: "",
        searched_food: "",
        clicked_food_name: "",
        cartItems: [],
        orderList: "",
        show_order_successful_msg: false,
        backendresponse: "",
        node_env: process.env.NODE_ENV,
        pizza_size_price: 0,
        pizza_size_text: ''
    },
    mutations: {
        SET_CATEGORY(state, payload) {
            state.selected_category = payload;
        },
        SET_FOOD(state, payload) {
            state.searched_food = payload;
        },
        SET_PIZZA_SIZE_PRICE(state, payload) {
            state.pizza_size_price = payload;
        },
        SET_PIZZA_SIZE_TEXT(state, payload) {
            state.pizza_size_text = payload;
        },
        RESET_CATEGORY(state) {
            state.selected_category = "";
        },
        RESET_FOOD(state) {
            state.searched_food = "";
        },
        SET_CLICKED_FOOD_NAME(state, payload) {
            state.clicked_food_name = payload;
        },
        SET_CART_ITEMS(state, payload) {
            state.cartItems.push(payload);
        },
        RESET_CART_ITEMS(state) {
            state.cartItems = [];
        },
        SET_ORDER_LIST(state, payload) {
            state.orderList += payload;
        },
        RESET_ORDER_LIST(state) {
            state.orderList = "";
        },
        SET_SUCCESSFUL_ORDER_MSG(state) {
            state.show_order_successful_msg = true;
        },
        RESET_SUCCESSFUL_ORDER_MSG(state) {
            state.show_order_successful_msg = false;
        },
        SET_BACKENDRESPONSE(state, payload) {
            state.backendresponse = payload;
        },
    },
    actions: {},
    modules: {},
});