<template>
  <div>
    <b-container fluid>
      <b-row class="menu">
        <b-container>
          <b-row>
            <b-col md="6" cols="6">
              <div class="form-group">
                <font-awesome-icon icon="tasks" size="lg" class="mr-2" /><label
                  for=""
                  >Kategorie auswählen</label
                >
                <select
                  @change="setCategory"
                  v-model="selected_category"
                  class="form-control"
                >
                  <option>Alles</option>
                  <option v-for="category in categories" :key="category.id">
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </b-col>

            <b-col md="6" cols="6">
              <div class="form-group">
                <font-awesome-icon icon="search" size="lg" class="mr-2" /><label
                  for=""
                  >Gerichte suchen</label
                >
                <input
                  v-model="searched_food"
                  type="text"
                  class="form-control"
                />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  background: rgb(214, 202, 36);
  padding: 15px 0px 15px 0px;
  color: white;
  margin-bottom: 15px;
}
label {
  font-size: 12px;
}
</style>
<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      categories: [],
      selected_category: "",
      searched_food: "",
    };
  },
  mounted() {
    this.getCategories();
  },
  computed: {
    ...mapState(["api_url"]),
  },
  methods: {
    getCategories() {
      axios
        .get(this.api_url + "/api/categories?populate=*")
        .then((response) => {
          this.categories = response.data.data.map((item) => item.attributes);
        });
    },
    setCategory() {
      this.$store.commit("SET_CATEGORY", this.selected_category);
    },
  },
  watch: {
    searched_food(fieldVal) {
      this.$store.commit("RESET_CATEGORY");
      this.$store.commit("SET_FOOD", fieldVal.toLowerCase());
    },
    selected_category(fieldVal) {
      if (fieldVal.length > 0) {
        this.$store.commit("RESET_FOOD");
      }
    },
  },
};
</script>
